import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CRM_PORT}${process.env.VUE_APP_API_PATH}/crm/checkout/carrinhos`;

export default {
    obterCarrinhoPublico(id) {
        return axiosJwt.get(
            `${api}/${id}/publico`
        );
    },

    selecionarFormaPagamento(id, formaPagamento) {
        return axiosJwt.patch(
            `${api}/${id}/publico/formapagto/${formaPagamento}`
        );
    },

    reiniciarFormaPagamento(id) {
        return axiosJwt.patch(
            `${api}/${id}/publico/formapagto/reiniciar`
        );
    },

    pagarCartao(pagamentoDto) {
        return axiosJwt.post(
            `${api}/${pagamentoDto.carrinhoId}/publico/pagarcartao`, pagamentoDto
        );
    },

    obterTodos(filtro) {
        return axiosJwt.get(
            `${api}?dataRegistroDe=${filtro.dataRegistroDe}&dataRegistroAte=${filtro.dataRegistroAte}`
        );
    },

    obterPorId(id) {
        return axiosJwt.get(
            `${api}/${id}`
        );
    },
};
