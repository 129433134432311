<template>
    <pagamento-pix :carrinho="carrinho"></pagamento-pix>
    <pagamento-cartao :carrinho="carrinho" @obterCarrinhoAtualizado="$emit('obterCarrinhoAtualizado')"></pagamento-cartao>
    <Button label="Escolher Outra Forma de Pagamento" class="mt-4" style="width: 100%" v-if="mostrarBtnEscolher" @click="reiniciarFormaPagamento" />
</template>

<script>
import { StatusPagamento } from './carrinhoEnums';
import Services from './services';
import PagamentoCartao from './PagamentoCartao';
import PagamentoPix from './PagamentoPix';

export default {
    components: {
        PagamentoCartao,
        PagamentoPix,
    },

    props: {
        carrinho: {
            type: Object,
        },
    },

    data() {
        return {};
    },

    emits: ['obterCarrinhoAtualizado'],

    methods: {
        reiniciarFormaPagamento() {
            this.$store.dispatch('addRequest');
            Services.reiniciarFormaPagamento(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.$emit('obterCarrinhoAtualizado');
                } else {
                    this.$store.dispatch('setErros', response.errors);
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        mostrarBtnEscolher() {
            return this.carrinho?.pagamento?.status == StatusPagamento.PENDENTE;
        },
    },
};
</script>