<template>
    <painel titulo="Carrinho" icone="pi pi-shopping-cart" v-if="carrinho" :refreshFunction="obterCarrinhoAtualizado">
        <div class="grid">
            <div class="col-12 md:col-8">
                <detalhe titulo="Nome">{{ carrinho.nome }}</detalhe>
                <detalhe titulo="Descrição">{{ carrinho.descricao }}</detalhe>

                <detalhe titulo="Status">
                    <status :status="carrinho.statusDescr"></status>
                </detalhe>
            </div>
            <div class="col-12 md:col-4">
                <span>VALOR TOTAL</span>
                <h1 class="m-0">{{ $numeral(carrinho.valorTotal).format('$0,0.00') }}</h1>
            </div>
        </div>
    </painel>
    <carrinho-itens :carrinho="carrinho" class="mt-4"></carrinho-itens>
</template>

<script>
import CarrinhoItens from './CarrinhoItens';

export default {
    emits: ['obterCarrinhoAtualizado'],

    props: {
        carrinho: {
            type: Object,
        },
    },

    components: {
        CarrinhoItens,
    },

    methods: {
        obterCarrinhoAtualizado() {
            this.$emit('obterCarrinhoAtualizado');
        },
    },
};
</script>
