<template>
    <div v-if="carrinho && carrinho.pagamento?.tipo == 1">
        <h5><i class="pi pi-money-bill"></i> Pagamento selecionado: CARTÃO</h5>
        <div class="formgrid grid p-fluid mt-4" v-if="carrinho.pagamento?.status != 1">
            <div class="field col-12 mt-4">
                <div class="grid">
                    <div class="col-12 md:col-3">
                        <div class="field-radiobutton">
                            <RadioButton id="option1" name="option" :value="0" v-model="tipo" />
                            <label>Crédito</label>
                        </div>
                    </div>
                    <div class="col-12 md:col-3">
                        <div class="field-radiobutton">
                            <RadioButton id="option2" name="option" :value="1" v-model="tipo" />
                            <label>Débito</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <label class="required">Bandeira</label>
                <Dropdown v-model="bandeira" :options="bandeiras" />
            </div>
            <div class="field col-12">
                <label class="required">Número do Cartão</label>
                <InputMask v-model="numeroCartao" mask="9999.9999.9999.9999" />
            </div>
            <div class="field col-12">
                <label class="required">Validade</label>
                <InputMask v-model="validade" mask="99/9999" />
            </div>
            <div class="field col-12">
                <label class="required">CVV</label>
                <InputMask v-model="cvv" mask="999" />
            </div>
            <div class="field col-12">
                <label class="required">Nome do Titular do Cartão</label>
                <InputText type="text" v-model="nomeTitular" />
            </div>
            <div class="field col-12">
                <label class="required">CPF</label>
                <InputMask v-model="cpf" mask="999.999.999-99" :unmask="true" />
            </div>
        </div>
        <Button
            label="Pagar"
            icon="pi pi-check-circle"
            class="p-button-primary"
            @click="pagar()"
            style="width: 100%"
            :disabled="v$.$invalid"
            v-if="carrinho.pagamento?.status != 1"
        />
        <div v-if="carrinho.pagamento?.status == 1 && carrinho.pagamento?.numeroCartaoCredito">
            <detalhe titulo="Cartão">{{ carrinho.pagamento?.numeroCartaoCredito }}</detalhe>
            <detalhe titulo="Nome">{{ carrinho.pagamento?.nomeCartaoCredito }}</detalhe>
            <detalhe titulo="Validade">{{ carrinho.pagamento?.validadeCartaoCredito }}</detalhe>
            <detalhe titulo="Autorização">{{ carrinho.pagamento?.autorizacaoCartaoCredito }}</detalhe>
        </div>
        <div v-if="carrinho.pagamento?.status == 1 && carrinho.pagamento?.numeroCartaoDebito">
            <detalhe titulo="Cartão">{{ carrinho.pagamento?.numeroCartaoDebito }}</detalhe>
            <detalhe titulo="Nome">{{ carrinho.pagamento?.nomeCartaoDebito }}</detalhe>
            <detalhe titulo="Validade">{{ carrinho.pagamento?.validadeCartaoDebito }}</detalhe>
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import Services from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        carrinho: {
            type: Object,
        },
    },

    data() {
        return {
            tipo: 0,
            numeroCartao: null,
            validade: null,
            cvv: null,
            nomeTitular: null,
            cpf: null,
            bandeira: 'Visa',
            bandeiras: ['Visa', 'Master', 'Amex', 'Elo', 'Aura', 'JCB', 'Diners', 'Discover', 'Hipercard', 'Hiper'],
        };
    },

    validations() {
        return {
            tipo: { required },
            numeroCartao: { required },
            validade: { required },
            cvv: { required },
            nomeTitular: { required },
            cpf: { required },
            bandeira: { required },
        };
    },

    emits: ['obterCarrinhoAtualizado'],

    methods: {
        pagar() {
            this.$store.dispatch('addRequest');
            let pagamentoDto = {
                carrinhoId: this.carrinho.carrinhoId,
                carrinhoPagamentoId: this.carrinho.pagamento.carrinhoPagamentoId,
                tipo: this.tipo,
                numeroCartao: this.numeroCartao,
                validade: this.validade,
                cvv: this.cvv,
                nomeTitular: this.nomeTitular,
                cpf: this.cpf,
                bandeira: this.bandeira,
            };
            Services.pagarCartao(pagamentoDto).then((response) => {
                if (response?.success) {
                    this.$emit('obterCarrinhoAtualizado');
                } else {
                    this.$store.dispatch('setErros', response.errors);
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>