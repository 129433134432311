<template>
    <div v-if="carrinho && carrinho.pagamento?.tipo == 0">
        <h5><i class="pi pi-money-bill"></i> Pagamento selecionado: PIX</h5>
        <div class="flex justify-content-center">
            <img width="300" height="300" :src="`data:image/PNG;base64,${carrinho.pagamento?.imagemQRCode}`" />
        </div>
        <h6>Copiar e Colar</h6>
        {{ carrinho.pagamento?.textoQRCode }}
    </div>
</template>

<script>
export default {
    props: {
        carrinho: {
            type: Object,
        },
    },
};
</script>