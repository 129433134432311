<template>
    <erros-box :fromBus="true"></erros-box>
    <div class="grid">
        <div class="col-12 md:col-9">
            <carrinho-detalhe :carrinho="carrinho" @obterCarrinhoAtualizado="obterCarrinho()"></carrinho-detalhe>
        </div>
        <div class="col-12 md:col-3">
            <forma-pagamento :carrinho="carrinho" @obterCarrinhoAtualizado="obterCarrinho()" v-if="!carrinho?.pagamento"></forma-pagamento>
            <pagamento-escolhido :carrinho="carrinho" @obterCarrinhoAtualizado="obterCarrinho()" v-if="carrinho?.pagamento"></pagamento-escolhido>
        </div>
    </div>
</template>

<script>
import CarrinhoDetalhe from './CarrinhoDetalhe';
import FormaPagamento from './FormaPagamento';
import PagamentoEscolhido from './PagamentoEscolhido';
import Services from './services';

export default {
    components: {
        CarrinhoDetalhe,
        FormaPagamento,
        PagamentoEscolhido,
    },

    data() {
        return {
            carrinho: null,
        };
    },

    methods: {
        obterCarrinho() {
            this.$store.dispatch('addRequest');
            Services.obterCarrinhoPublico(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.carrinho = response.data;
                } else {
                    this.carrinho = null;
                    this.$store.dispatch('setErros', response.errors);
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    created() {
        this.$store.dispatch('trocarMenu');
    },

    mounted() {
        this.obterCarrinho();
    },
};
</script>