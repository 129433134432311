<template>
    <div v-if="carrinho">
        <h5><i class="pi pi-money-bill"></i> Forma de pagamento</h5>
        <Button type="button" class="p-button-outlined mt-1" style="width: 100%" @click="selecionarFormaPagamento('pix')">
            <img alt="logo" src="layout\images\logo-pix.png" style="width: 100%" />
        </Button>
        <Button type="button" class="p-button-outlined mt-4" style="width: 100%" @click="selecionarFormaPagamento('cartao')">
            <img alt="logo" src="layout\images\cielo.png" style="width: 100%" />
        </Button>
    </div>
</template>

<script>
import Services from './services';

export default {
    props: {
        carrinho: {
            type: Object,
        },
    },

    emits: ['obterCarrinhoAtualizado'],

    methods: {
        selecionarFormaPagamento(formaPagamento) {
            this.$store.dispatch('addRequest');
            Services.selecionarFormaPagamento(this.$route.params.id, formaPagamento).then((response) => {
                if (response?.success) {
                    this.$emit('obterCarrinhoAtualizado');
                } else {
                    this.$store.dispatch('setErros', response.errors);
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>